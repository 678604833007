import React from 'react';
import TextCard from '~/components/molecules/TextCard';
import Image from '~/components/atoms/Image';
import Arrow from '~/components/atoms/Arrow';
import useScrollToNextSection from '~/hooks/useScrollToNextSection';


export default function HeroResourceDownload({ title = '',  categories, author, download }) {
  const { ref, scrollToNextSection } = useScrollToNextSection();

  const authorText = author?.firstName?.length > 0 ? `Written by ${author.firstName} ${author.lastName}` : '';


  const textCard = {
    "heading": {
      "type": "h1",
      "text": title
    },
    "subheading": categories.nodes[0]?.name || 'Article',
    "subheadingClassName": "!text-blue",
    "content": authorText,
    "buttons": [
      {
        "button": {
          "button": {
            "button": {
              "title": download.button.title,
              "url": download.button.url,
              "target": download.button.target
            }
          },
          "type": "button"
        }
      }
    ]
  }



  return (
    <section ref={ref} className="mb-20 md:mb-36 pt-32 lg:pt-40">
      <div className="container ">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 md:col-span-5 h-full flex flex-col justify-center items-center">
            <TextCard className="text-center pt-20 md:pt-0 xl:pt-20 mb-24 mt-auto"
              {...textCard}
            />
            <button className="mt-auto " onClick={scrollToNextSection}>
              <Arrow color="navy" className="!w-14 !h-14" />
            </button>
          </div>
          <div className="col-span-12 md:col-span-5 md:col-start-8">
            <Image data={download.image} className={`w-full h-auto `} />
          </div>
        </div>
      </div>
    </section>
  );
}
