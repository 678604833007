import React from 'react';
import TextCard from '~/components/molecules/TextCard';
import Arrow from '~/components/atoms/Arrow';
import useScrollToNextSection from '~/hooks/useScrollToNextSection';


export default function HeroResourceEmbed({ title = '',  categories, author, embed }) {
  const { ref, scrollToNextSection } = useScrollToNextSection();

  const heading = {
    "type": "h1",
    "text": title
  }

  const authorText = author?.firstName?.length > 0 ? `Written by ${author.firstName} ${author.lastName}` : '';

  return (
    <section ref={ref} className="mb-20 md:mb-36 pt-32 lg:pt-52">
      <div className="container px-0">
        <div className="max-w-4xl mx-auto space-y-20">
          <div className="flex flex-col justify-center items-center space-y-12">
            <TextCard
              className="text-center"
              subheading={categories.nodes[0]?.name || 'Article'}
              subheadingClassName="!text-blue"
              heading={heading}
              content={authorText}
            />
            <button className="" onClick={scrollToNextSection}>
              <Arrow color="navy" className="!w-14 !h-14" />
            </button>
          </div>
          <div className="w-full" dangerouslySetInnerHTML={{__html: embed}} />
        </div>
      </div>
    </section>
  );
}
