import React from 'react';
import TextCard from '~/components/molecules/TextCard';
import Image from '~/components/atoms/Image';
import Arrow from '~/components/atoms/Arrow';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function HeroResource({ title = '', image, categories, author }) {
  const heading = {
    "type": "h1",
    "text": title
  }
  const authorText = author?.firstName?.length > 0 ? `Written by ${author.firstName} ${author.lastName}` : '';
  return (
    <section className="mb-20 md:mb-36 pt-14 lg:pt-20">
      <div className="container px-0">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 md:col-span-6 ">
            <FadeInWhenVisible className="w-full">
              <div className="aspect-w-15 aspect-h-16 bg-sand">
                <Image loading={'eager'} data={image} className={`!absolute w-full h-full`} />
              </div>
            </FadeInWhenVisible>
          </div>
          <FadeInWhenVisible className="col-span-12 md:col-span-6 h-full flex flex-col justify-center items-center">
            <TextCard
              className="text-center col-span-5 pt-20 md:pt-0 xl:pt-32 px-12 lg:px-20 xl:px-32 mt-auto"
              subheading={categories.nodes[0]?.name || 'Article'}
              subheadingClassName="!text-blue"
              heading={heading}
              content={authorText}
            />
            <a href="#article-content" className="mt-4 lg:mt-auto mb-14">
              <Arrow color="navy" className="!w-14 !h-14" />
            </a>
          </FadeInWhenVisible>
        </div>
      </div>
    </section>
  );
}
